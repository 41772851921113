import React from 'react'
import { Button, Icon, Item } from 'semantic-ui-react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

const ResortItem = props => {

  const resort = props.p.node

  return (
    <Item>
      <Item.Image>
        <Img fixed={ resort.headerImage.fixed } />
      </Item.Image>
      <Item.Content>
        <Item.Header 
          as='a'
          style={{
            fontFamily: 'Playfair Display',
            fontWeight: '700',
          }}>
          { resort.resortName }
        </Item.Header>
        <Item.Meta>
          { resort.location }
        </Item.Meta>
        <Item.Description
          style={{
            fontFamily: 'Playfair Display',
            fontWeight: '400',
            minHeight: '5.6rem'
          }}
        >          
          { resort.resortDescriptionNode.childMarkdownRemark.excerpt }

        </Item.Description>
        <Item.Extra >
          <Link to={`/osrodki/${resort.uid}`}>
            <Button primary floated='right'>
              Zobacz Więcej
              <Icon name='right chevron' />
            </Button>
          </Link>
        </Item.Extra>
      </Item.Content>
    </Item>

  )
}


export default ResortItem