import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import HeroSmall from '../components/HeroSmall'
import ResortItem from '../components/ResortItem'

import { ContentWrapper } from '../components/Elements'

import { Grid, Item } from 'semantic-ui-react'

import CTASection from '../components/CTA Section'

const ResortPage = () => (
  <StaticQuery
    query={graphql`
      query allResortQuery {
        allDatoCmsResort {
          edges {
            node {
              id
              uid
              resortName
              headerImage {
                fixed(width: 175, height: 175) {
                ...GatsbyDatoCmsFixed
                }
              }
              resortDescriptionNode {
                childMarkdownRemark {
                  id
                  html
                  excerpt(pruneLength: 180)
                }
              }
              resortFeaturesNode {
                childMarkdownRemark {
                  id
                  html
                }
              }
            }
          }
        }
        datoCmsHeadersSection(name: { eq: "ośrodki" }) {
          id
          name
          header
          heroImage {
            fixed(width: 960) {
            ...GatsbyDatoCmsFixed
            }
            fluid(maxWidth: 960) {
            ...GatsbyDatoCmsFluid
            }
          }
          maxHeight
        }
      }
    `}
    render={data => (
      <Layout>
        <SEO title='Ośrodki' />
        <HeroSmall data={ data.datoCmsHeadersSection } />
        <ContentWrapper>
          <Grid centered >
            <Grid.Row columns={1}>
              <Grid.Column>
                <Item.Group divided>
                  { data.allDatoCmsResort.edges.map((node, index) => (
                    <ResortItem key={ index } p={ node } />
                  ))}
                </Item.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ContentWrapper>
        <CTASection />
      </Layout>
    )}
  />
)

export default ResortPage